@import '../../variables.scss';

.entityList {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	position: relative;
	table-layout: fixed;
	// to avoid scrollbar and to keep border collapse
	width: 99.9%;
	.entityListDeleteIconContainer {
		box-sizing: border-box;
		height: 32px;
		width: 36px;
		button{
			visibility: hidden;
		}
		&:hover {
            cursor: pointer;
			button {
				visibility: visible;
			}
        }
	}

	:global(.ms-TextField-fieldGroup) {
		background-color: $grey_f3;
		border: 0;
		margin-right: 8px;
	}

	.listHeader {
		> * {
			text-align: left;
			vertical-align: top;
		}
	}

	.inputContainer {
		padding: 0;
		:global(.ms-TextField:not(.voteWeightField):not(.answerInputField)) {
			> span {
				display: block;
				padding: 0px 8px;
			}
		}
		:global(.voteWeightField) {
			width: 90px;
		}
		&.inputFocused {
            border-bottom: 2px solid #6264A7;
		}
	}


	.listItem {
		&:hover {
			.entityListDeleteIconContainer,
			& > *:first-child {
				visibility: visible;
			}
		}
		.entityListDeleteIconContainer {
			width: 36px;
		}
	}
}

.newListItem {
	align-items: center;
	border: 0;
	display: flex;
	height: 32px;
	justify-content: space-between;
	margin: 0;
	padding-top: 4px;
	width: 100%;
	&:focus {
		border-bottom: 2px solid #6264A7;
	}
	:global{
		.ms-Button,
		.ms-Button:hover {
			background-color: $grey_f3;
			flex-grow: 1;
			flex-shrink: 1;
			margin-right: 8px;
			padding-left: 8px;
		}
		.ms-Button--action,
		.ms-Button--action:hover  {
			cursor: pointer;
			height: 32px;
			.ms-Button-textContainer .ms-Button-label {
				color: $grey_85;
				text-decoration-line: none !important;
			}
			&::after {
				display: none !important;
			}
		}
	}
	.newListEmptyContainer {
		width: 34px;
	}
}
