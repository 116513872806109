@import '../../variables.scss';
.toggleDatePicker {
	background-color: inherit;
	border-bottom: 2px solid transparent;
	display: flex;
	margin-bottom: -2px;
	max-width: 140px;
	& > div {
		width: 100%;
	}
	& > span {
		line-height: 32px;
	}
	:global(.ms-TextField-field) {
		line-height: 32px;
		background-color: transparent;
		&:focus {
			border-bottom: 0;
		}
	}
	&.editMode {
		background-color: $white;
		border-bottom: 2px solid $violet_62;
	}

	& > :global(.ms-Button--icon):hover {
		background-color: transparent;
	}
}
